exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alquimia-tsx": () => import("./../../../src/pages/alquimia.tsx" /* webpackChunkName: "component---src-pages-alquimia-tsx" */),
  "component---src-pages-antes-que-me-olvide-tsx": () => import("./../../../src/pages/antes-que-me-olvide.tsx" /* webpackChunkName: "component---src-pages-antes-que-me-olvide-tsx" */),
  "component---src-pages-basquetbol-tsx": () => import("./../../../src/pages/basquetbol.tsx" /* webpackChunkName: "component---src-pages-basquetbol-tsx" */),
  "component---src-pages-columnistas-tsx": () => import("./../../../src/pages/columnistas.tsx" /* webpackChunkName: "component---src-pages-columnistas-tsx" */),
  "component---src-pages-economia-tsx": () => import("./../../../src/pages/economia.tsx" /* webpackChunkName: "component---src-pages-economia-tsx" */),
  "component---src-pages-el-menu-del-dia-tsx": () => import("./../../../src/pages/el-menu-del-dia.tsx" /* webpackChunkName: "component---src-pages-el-menu-del-dia-tsx" */),
  "component---src-pages-empresariales-tsx": () => import("./../../../src/pages/empresariales.tsx" /* webpackChunkName: "component---src-pages-empresariales-tsx" */),
  "component---src-pages-futbol-tsx": () => import("./../../../src/pages/futbol.tsx" /* webpackChunkName: "component---src-pages-futbol-tsx" */),
  "component---src-pages-hablando-al-sur-jose-mujica-tsx": () => import("./../../../src/pages/hablando-al-sur-jose-mujica.tsx" /* webpackChunkName: "component---src-pages-hablando-al-sur-jose-mujica-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-24-tsx": () => import("./../../../src/pages/info24.tsx" /* webpackChunkName: "component---src-pages-info-24-tsx" */),
  "component---src-pages-informe-granjero-tsx": () => import("./../../../src/pages/informe-granjero.tsx" /* webpackChunkName: "component---src-pages-informe-granjero-tsx" */),
  "component---src-pages-internacionales-tsx": () => import("./../../../src/pages/internacionales.tsx" /* webpackChunkName: "component---src-pages-internacionales-tsx" */),
  "component---src-pages-justicia-infinita-tsx": () => import("./../../../src/pages/justicia-infinita.tsx" /* webpackChunkName: "component---src-pages-justicia-infinita-tsx" */),
  "component---src-pages-la-cuchara-tsx": () => import("./../../../src/pages/la-cuchara.tsx" /* webpackChunkName: "component---src-pages-la-cuchara-tsx" */),
  "component---src-pages-la-rosca-tsx": () => import("./../../../src/pages/la-rosca.tsx" /* webpackChunkName: "component---src-pages-la-rosca-tsx" */),
  "component---src-pages-maldonado-tsx": () => import("./../../../src/pages/maldonado.tsx" /* webpackChunkName: "component---src-pages-maldonado-tsx" */),
  "component---src-pages-mejor-hablar-tsx": () => import("./../../../src/pages/mejor-hablar.tsx" /* webpackChunkName: "component---src-pages-mejor-hablar-tsx" */),
  "component---src-pages-menuinformativo-tsx": () => import("./../../../src/pages/menuinformativo.tsx" /* webpackChunkName: "component---src-pages-menuinformativo-tsx" */),
  "component---src-pages-mundo-tsx": () => import("./../../../src/pages/mundo.tsx" /* webpackChunkName: "component---src-pages-mundo-tsx" */),
  "component---src-pages-nadaqueperder-tsx": () => import("./../../../src/pages/nadaqueperder.tsx" /* webpackChunkName: "component---src-pages-nadaqueperder-tsx" */),
  "component---src-pages-noticias-tsx": () => import("./../../../src/pages/noticias.tsx" /* webpackChunkName: "component---src-pages-noticias-tsx" */),
  "component---src-pages-otras-maneras-tsx": () => import("./../../../src/pages/otras-maneras.tsx" /* webpackChunkName: "component---src-pages-otras-maneras-tsx" */),
  "component---src-pages-politica-tsx": () => import("./../../../src/pages/politica.tsx" /* webpackChunkName: "component---src-pages-politica-tsx" */),
  "component---src-pages-por-decir-algo-tsx": () => import("./../../../src/pages/por-decir-algo.tsx" /* webpackChunkName: "component---src-pages-por-decir-algo-tsx" */),
  "component---src-pages-publicaciones-guardadas-tsx": () => import("./../../../src/pages/publicaciones-guardadas.tsx" /* webpackChunkName: "component---src-pages-publicaciones-guardadas-tsx" */),
  "component---src-pages-sociedad-tsx": () => import("./../../../src/pages/sociedad.tsx" /* webpackChunkName: "component---src-pages-sociedad-tsx" */),
  "component---src-pages-tapalo-con-radio-tsx": () => import("./../../../src/pages/tapalo-con-radio.tsx" /* webpackChunkName: "component---src-pages-tapalo-con-radio-tsx" */),
  "component---src-pages-todo-por-la-misma-plata-tsx": () => import("./../../../src/pages/todo-por-la-misma-plata.tsx" /* webpackChunkName: "component---src-pages-todo-por-la-misma-plata-tsx" */),
  "component---src-pages-ultimo-cuarto-tsx": () => import("./../../../src/pages/ultimo-cuarto.tsx" /* webpackChunkName: "component---src-pages-ultimo-cuarto-tsx" */),
  "component---src-pages-universos-paralelos-tsx": () => import("./../../../src/pages/universos-paralelos.tsx" /* webpackChunkName: "component---src-pages-universos-paralelos-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-events-tsx": () => import("./../../../src/templates/events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */),
  "component---src-templates-post-no-image-tsx": () => import("./../../../src/templates/post-no-image.tsx" /* webpackChunkName: "component---src-templates-post-no-image-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

